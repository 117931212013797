<template>
  <container ref="container" class="caregiverDetail" @error="init">
    <!-- 顶部 -->
    <publicHeader></publicHeader>

    <!-- 横幅 -->
    <publicBanner></publicBanner>

    <!-- 面包屑导航 -->
    <breadcrumb :router="['首页', '详情']"></breadcrumb>

    <div class="caregiverDetailPC">
      <!-- 简介 -->
      <div class="caregiverDetailPC-introduction">
        <div class="caregiverDetailPC-introduction-detail">
          <div class="caregiverDetailPC-introduction-lv">
            <img src="@/assets/image/public/lv.png" />
            <span>{{ detail.servicelevel }}</span>
          </div>
          <div
            class="caregiverDetailPC-introduction-sign"
            v-if="detail.signupdata"
          >
            <img src="@/assets/image/public/sign.png" />
          </div>
          <div class="caregiverDetailPC-introduction-left">
            <div class="caregiverDetailPC-introduction-avatar">
              <img
                :src="detail.photo"
                class="caregiverDetailPC-introduction-avatar-img"
              />
            </div>
            <!-- <div class="caregiverDetailPC-introduction-price">查询报价</div> -->
          </div>
          <div class="caregiverDetailPC-introduction-right">
            <div class="caregiverDetailPC-introduction-name">
              {{ detail.name }}
            </div>
            <div class="caregiverDetailPC-introduction-info">
              <div>{{ detail.hometown }}</div>
              <div>{{ detail.age }}岁</div>
            </div>
            <div class="caregiverDetailPC-introduction-data">
              <div class="caregiverDetailPC-introduction-hot">
                <img src="@/assets/image/public/hot.png" /> {{ detail.hot }}
              </div>
              <div class="caregiverDetailPC-introduction-evaluate">
                <img src="@/assets/image/public/evaluate.png" />
                {{ detail.goodevl }}
              </div>
            </div>
            <div class="caregiverDetailPC-introduction-star">
              <iconfont
                v-for="item in 5"
                :key="item"
                class="caregiverDetailPC-introduction-star-icon"
                size="26px"
                :name="item <= detail.starrating ? 'star-full' : 'star'"
              />
            </div>
            <div class="caregiverDetailPC-introduction-certificates">
              <div
                class="caregiverDetailPC-introduction-certificates-element"
                :key="item"
                v-for="item in detail.cardstr"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="caregiverDetailPC-introduction-map">
          <img
            class="caregiverDetailPC-introduction-map-img"
            :src="detail.homepic"
          />
        </div>
      </div>

      <div class="caregiverDetailPC-flex">
        <!-- 详情 -->
        <div class="caregiverDetailPC-section caregiverDetailPC-details">
          <div class="caregiverDetailPC-section-header">她的信息</div>
          <div
            class="
              caregiverDetailPC-section-body caregiverDetailPC-details-body
            "
          >
            <div class="caregiverDetailPC-details-line">
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">出生日期：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.borndate }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">文化程度：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.educationlist }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">属相：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.zodiac }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">工作年限：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.workage }}
                </div>
              </div>
            </div>
            <div class="caregiverDetailPC-details-line">
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">星座：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.constellation }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">婚姻状况：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ marriagedata }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">民族：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.nationality }}
                </div>
              </div>
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">服务数量：</div>
                <div class="caregiverDetailPC-details-content">
                  {{ detail.servicesnum }}个家庭
                </div>
              </div>
            </div>
            <div class="caregiverDetailPC-details-textarea">
              <div class="caregiverDetailPC-details-item">
                <div class="caregiverDetailPC-details-title">客户评价：</div>
                <div
                  class="caregiverDetailPC-details-content"
                  v-html="detail.customereval"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 培训 -->
        <div class="caregiverDetailPC-section caregiverDetailPC-training">
          <div class="caregiverDetailPC-section-header">她的培训</div>
          <div
            class="
              caregiverDetailPC-section-body caregiverDetailPC-training-list
            "
            v-if="detail.training && detail.training.length"
          >
            <div
              class="caregiverDetailPC-training-item"
              v-for="item in detail.training"
              :key="item.id"
            >
              <div class="caregiverDetailPC-training-item-date">
                {{ item.riqi }}
              </div>
              <div class="caregiverDetailPC-training-item-title">
                {{ item.name }}
              </div>
              <div class="caregiverDetailPC-training-item-lv">
                {{ item.worktype }}
              </div>
            </div>
          </div>
          <div class="caregiverDetailPC-training-empty" v-else>
            无培训记录
          </div>
        </div>
      </div>

      <!-- 照片 -->
      <div class="caregiverDetailPC-section caregiverDetailPC-pic">
        <div class="caregiverDetailPC-section-header">她的照片</div>
        <div class="caregiverDetailPC-section-body caregiverDetailPC-pic-list">
          <div
            class="caregiverDetailPC-pic-item"
            v-for="(item, index) in detail.atlas"
            :key="index"
          >
            <img :src="item" />
          </div>
        </div>
      </div>

      <!-- 点评 -->
      <div class="caregiverDetailPC-section caregiverDetailPC-evaluate">
        <div class="caregiverDetailPC-section-header">妈咪点评</div>
        <div
          class="caregiverDetailPC-section-body caregiverDetailPC-evaluate-list"
        >
          <div
            class="caregiverDetailPC-evaluate-item"
            v-for="item in detail.evl"
            :key="item.id"
          >
            <div class="caregiverDetailPC-evaluate-item-header">
              <div class="caregiverDetailPC-evaluate-item-name">
                {{ item.evlname }}
              </div>
              <div class="caregiverDetailPC-evaluate-item-location">
                {{ item.evladdress }}
              </div>
              <div class="caregiverDetailPC-evaluate-item-date">
                {{ item.createtime }}
              </div>
              <div class="caregiverDetailPC-evaluate-item-channel">
                来自爱月宝
              </div>
            </div>
            <div class="caregiverDetailPC-evaluate-item-content">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="caregiverDetailMobile">
      <!-- 简介 -->
      <div class="caregiverDetailMobile-introduction">
        <div class="caregiverDetailMobile-introduction-detail">
          <div class="caregiverDetailMobile-introduction-lv">
            <img src="@/assets/image/public/lv.png" />
            <span>{{ detail.servicelevel }}</span>
          </div>
          <div
            class="caregiverDetailMobile-introduction-sign"
            v-if="detail.signupdata"
          >
            <img src="@/assets/image/public/sign.png" />
          </div>
          <div class="caregiverDetailMobile-introduction-left">
            <div class="caregiverDetailMobile-introduction-avatar">
              <img
                :src="detail.photo"
                class="caregiverDetailMobile-introduction-avatar-img"
              />
            </div>
            <!-- <div class="caregiverDetailMobile-introduction-price">查询报价</div> -->
          </div>
          <div class="caregiverDetailMobile-introduction-right">
            <div class="caregiverDetailMobile-introduction-name">
              {{ detail.name }}
            </div>
            <div class="caregiverDetailMobile-introduction-info">
              <div>{{ detail.hometown }}</div>
              <div>{{ detail.age }}岁</div>
            </div>
            <div class="caregiverDetailMobile-introduction-data">
              <div class="caregiverDetailMobile-introduction-hot">
                <img src="@/assets/image/public/hot.png" /> {{ detail.hot }}
              </div>
              <div class="caregiverDetailMobile-introduction-evaluate">
                <img src="@/assets/image/public/evaluate.png" />
                {{ detail.goodevl }}
              </div>
            </div>
            <div class="caregiverDetailMobile-introduction-star">
              <iconfont
                v-for="item in 5"
                :key="item"
                class="caregiverDetailMobile-introduction-star-icon"
                size="40rpx"
                :name="item <= detail.starrating ? 'star-full' : 'star'"
              />
            </div>
            <div class="caregiverDetailMobile-introduction-certificates">
              <div
                class="caregiverDetailMobile-introduction-certificates-element"
                :key="item"
                v-for="item in detail.cardstr"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 详情 -->
      <div class="caregiverDetailMobile-section caregiverDetailMobile-details">
        <div class="caregiverDetailMobile-section-header">
          <iconfont
            class="caregiverDetailMobile-section-header-icon"
            size="24px"
            name="header"
          />她的信息
        </div>
        <div
          class="
            caregiverDetailMobile-section-body
            caregiverDetailMobile-details-body
          "
        >
          <div class="caregiverDetailMobile-details-line">
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">出生日期：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.borndate }}
              </div>
            </div>
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">文化程度：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.educationlist }}
              </div>
            </div>
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">属相：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.zodiac }}
              </div>
            </div>
          </div>
          <div class="caregiverDetailMobile-details-line">
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">星座：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.constellation }}
              </div>
            </div>
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">婚姻状况：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ marriagedata }}
              </div>
            </div>
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">民族：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.nationality }}
              </div>
            </div>
          </div>
          <div class="caregiverDetailMobile-details-line">
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">工作年限：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.workage }}
              </div>
            </div>
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">服务数量：</div>
              <div class="caregiverDetailMobile-details-content">
                {{ detail.servicesnum }}个家庭
              </div>
            </div>
          </div>
          <div class="caregiverDetailMobile-details-textarea">
            <div class="caregiverDetailMobile-details-item">
              <div class="caregiverDetailMobile-details-title">客户评价：</div>
              <div
                class="caregiverDetailMobile-details-content"
                v-html="detail.customereval"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 培训 -->
      <div class="caregiverDetailMobile-section caregiverDetailMobile-training">
        <div class="caregiverDetailMobile-section-header">
          <iconfont
            class="caregiverDetailMobile-section-header-icon"
            size="24px"
            name="header"
          />她的培训
        </div>
        <div
          v-if="detail.training && detail.training.length"
          class="
            caregiverDetailMobile-section-body
            caregiverDetailMobile-training-list
          "
        >
          <div
            class="caregiverDetailMobile-training-item"
            v-for="item in detail.training"
            :key="item.id"
          >
            <div class="caregiverDetailMobile-training-item-date">
              {{ item.riqi }}
            </div>
            <div class="caregiverDetailMobile-training-item-title">
              {{ item.name }}
            </div>
            <div class="caregiverDetailMobile-training-item-lv">
              {{ item.worktype }}
            </div>
          </div>
        </div>
        <div class="caregiverDetailMobile-training-empty" v-else>
          无培训记录
        </div>
      </div>

      <!-- 照片 -->
      <div class="caregiverDetailMobile-section caregiverDetailMobile-pic">
        <div class="caregiverDetailMobile-section-header">
          <iconfont
            class="caregiverDetailMobile-section-header-icon"
            size="24px"
            name="header"
          />她的照片
        </div>
        <div
          class="
            caregiverDetailMobile-section-body caregiverDetailMobile-pic-list
          "
        >
          <div
            class="caregiverDetailMobile-pic-item"
            v-for="(item, index) in detail.atlas"
            :key="index"
          >
            <img :src="item" />
          </div>
        </div>
      </div>

      <div class="caregiverDetailMobile-section caregiverDetailMobile-map">
        <div class="caregiverDetailMobile-section-header">
          <iconfont
            class="caregiverDetailMobile-section-header-icon"
            size="24px"
            name="header"
          />她的地址
        </div>
        <div class="caregiverDetailMobile-section-body">
          <div class="caregiverDetailMobile-map">
            <img class="caregiverDetailMobile-map-img" :src="detail.homepic" />
          </div>
        </div>
      </div>

      <!-- 点评 -->
      <div class="caregiverDetailMobile-section caregiverDetailMobile-evaluate">
        <div class="caregiverDetailMobile-section-header">
          <iconfont
            class="caregiverDetailMobile-section-header-icon"
            size="24px"
            name="header"
          />妈咪点评
        </div>
        <div
          class="
            caregiverDetailMobile-section-body
            caregiverDetailMobile-evaluate-list
          "
        >
          <div
            class="caregiverDetailMobile-evaluate-item"
            v-for="item in detail.evl"
            :key="item.id"
          >
            <div class="caregiverDetailMobile-evaluate-item-header">
              <div class="caregiverDetailMobile-evaluate-item-name">
                {{ item.evlname }}
              </div>
              <div class="caregiverDetailMobile-evaluate-item-location">
                {{ item.evladdress }}
              </div>
              <div class="caregiverDetailMobile-evaluate-item-date">
                {{ item.createtime }}
              </div>
              <div class="caregiverDetailMobile-evaluate-item-channel">
                来自爱月宝
              </div>
            </div>
            <div class="caregiverDetailMobile-evaluate-item-content">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <publicFooter></publicFooter>

    <!-- 联系 -->
    <contact></contact>
  </container>
</template>

<script>
import publicHeader from "@/components/publicHeader.vue";
import publicFooter from "@/components/publicFooter.vue";
import publicBanner from "@/components/publicBanner.vue";
import contact from "@/components/contact.vue";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "caregiverDetail",
  components: {
    publicHeader,
    publicFooter,
    publicBanner,
    breadcrumb,
    contact,
  },
  computed: {
    marriagedata() {
      const result = ["婚姻", "未婚", "已婚"];
      return result[this.detail.marriagedata];
    },
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const detail = await this.$axios("/api/mainstation/yuesaoDetail", {
          params: {
            id: this.$route.query.id,
          },
        });

        // 设置数据
        this.detail = detail.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .caregiverDetailMobile {
    display: none;
  }
  //
  .caregiverDetailPC {
    // 信息
    &-introduction {
      width: 1200px;
      margin: auto;
      margin-top: 40px;
      display: flex;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;

      &-detail {
        width: 600px;
        position: relative;
        box-sizing: border-box;
        display: flex;
        padding: 30px;
        flex-shrink: 0;
      }

      &-left {
        width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-right {
      }

      // 头像
      &-avatar {
        width: 210px;
        height: 210px;
        border-radius: 50%;
        overflow: hidden;

        &-img {
          @include cover;
        }
      }

      // 询价
      &-price {
        border-radius: 22px;
        line-height: 44px;
        text-align: center;
        background-color: #ed207b;
        color: #fff;
        width: 130px;
        height: 44px;
        margin-top: 24px;
        cursor: pointer;
      }

      // 名字
      &-name {
        color: #333333;
        font-size: 28px;
      }

      // 信息
      &-info {
        margin-top: 10px;
        display: flex;
        font-size: 16px;

        > div:not(:last-of-type)::after {
          content: "|";
          display: inline-block;
          margin: 0 5px;
        }
      }

      // 数据
      &-data {
        display: flex;
        align-items: center;
        margin-top: 15px;
      }

      &-hot,
      &-evaluate {
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          display: block;
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      &-hot {
        color: #ed207b;
      }

      &-evaluate {
        color: #9bdbda;
      }

      &-sign {
        position: absolute;
        right: 0;
        top: 0;
      }

      &-lv {
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        color: #ed207b;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: 34px;
          height: 34px;
        }

        span {
          position: absolute;
          bottom: 15px;
          right: 20px;
          font-size: 20px;
          font-style: italic;
        }
      }

      &-star {
        display: flex;
        margin-top: 10px;

        &-icon {
          color: #ed207b;
          margin: 0 2px;
        }
      }

      &-certificates {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        &-element {
          color: #fff;
          background-color: #ed207b;
          padding: 0 6px;
          margin-right: 16px;
          margin-bottom: 16px;
          line-height: 28px;
          font-size: 16px;
          border-radius: 5px;
        }
      }

      // 地图
      &-map {
        width: 600px;
        box-sizing: border-box;
        flex-shrink: 0;
        border-left: 1px solid #eaeaea;
        border-radius: 10px;

        img {
          @include cover;
        }
      }
    }

    // 详情
    &-details {
      width: 800px;
      height: 340px;

      &-body {
        padding: 20px;
        display: flex;
        flex-direction: column;
      }

      // 详情-文本
      &-textarea {
        width: 100%;
      }

      &-textarea &-content {
        overflow: hidden;
        height: 160px;
      }

      // 详情-列表
      &-line {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      &-line &-item {
        &:nth-of-type(1) {
          width: 30%;
        }

        &:nth-of-type(2) {
          width: 25%;
        }

        &:nth-of-type(3) {
          width: 20%;
        }

        &:nth-of-type(4) {
          width: 25%;
        }
      }

      // 详情-元素
      &-item {
        display: flex;
      }

      // 详情-标题
      &-title {
        color: #ed207b;
        font-size: 18px;
        line-height: 32px;
      }

      // 详情-内容
      &-content {
        color: #353535;
        font-size: 18px;
        line-height: 32px;
        flex: 1;
        width: 0;
      }
    }

    // 段落
    &-section {
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #eaeaea;
      box-sizing: border-box;

      // 段落-顶部
      &-header {
        height: 50px;
        line-height: 50px;
        width: 150px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        background-color: #ed207b;
      }
      // 段落-主体
      &-body {
      }
    }

    // flex
    &-flex {
      display: flex;
      margin: auto;
      margin-top: 40px;
      width: 1200px;
    }

    // 培训
    &-training {
      width: 380px;
      margin-left: 20px;

      &-empty {
        color: #353535;
        // height: 100px;
        // line-height: 140px;
        padding-top: 100px;
        // padding-bottom: rpx(40);
        font-size: 14px;
        text-align: center;
      }

      // 培训-列表
      &-list {
        padding: 20px;
      }

      // 培训-元素
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 18px;

        &-date {
          color: #ed207b;
        }

        &-title {
          color: #353535;
          @include ellipsis;
          width: 0;
          flex: 1;
          margin: 0 20px;
        }

        &-lv {
          color: #ed207b;
          @include ellipsis;
          width: 100px;
        }
      }
    }

    // 照片
    &-pic {
      width: 1200px;
      margin: auto;
      margin-top: 30px;

      &-list {
        padding: 40px;
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: 248px;
        height: 250px;
        margin-right: 40px;
        margin-bottom: 40px;

        &:nth-of-type(4n) {
          margin-right: 0;
        }

        &:nth-last-of-type(-n + 4) {
          margin-bottom: 0;
        }

        img {
          @include cover;
        }
      }
    }

    // 评论
    &-evaluate {
      width: 1200px;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;

      // 评论-列表
      &-list {
        padding: 20px;
      }

      // 评论-元素
      &-item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px dashed #cecece;

        // 评论-元素-顶部
        &-header {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #2f2f2f;
        }

        // 名称
        &-name,
        &-location {
          &::after {
            content: "|";
            display: inline-block;
            margin: 0 20px;
          }
        }

        // 来自
        &-channel {
          margin-left: 60px;
          color: #ed207b;
        }

        // 内容
        &-content {
          color: #bcbcbc;
          margin-top: 10px;
          font-size: 18px;
        }
      }
    }
  }
}
//
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .caregiverDetailPC {
    display: none;
  }
  //
  .caregiverDetailMobile {
    // 信息
    &-introduction {
      margin: 0 rpx(30);
      margin-top: rpx(40);
      display: flex;
      border: rpx(2) solid #eaeaea;
      border-radius: rpx(10);
      position: relative;

      &-detail {
        flex: 1;
        position: relative;
        display: flex;
        padding: rpx(30);
      }

      &-left {
        width: rpx(260);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-right {
      }

      // 头像
      &-avatar {
        width: rpx(210);
        height: rpx(210);
        border-radius: 50%;
        overflow: hidden;

        &-img {
          @include cover;
        }
      }

      // 询价
      &-price {
        border-radius: 22px;
        line-height: 44px;
        text-align: center;
        background-color: #ed207b;
        color: #fff;
        width: 130px;
        height: 44px;
        margin-top: 24px;
        cursor: pointer;
      }

      // 名字
      &-name {
        color: #333333;
        font-size: rpx(28);
      }

      // 信息
      &-info {
        margin-top: rpx(10);
        display: flex;
        font-size: rpx(24);

        > div:not(:last-of-type)::after {
          content: "|";
          display: inline-block;
          margin: 0 rpx(10);
        }
      }

      // 数据
      &-data {
        display: flex;
        align-items: center;
        margin-top: rpx(30);
      }

      &-hot,
      &-evaluate {
        font-size: rpx(24);
        display: flex;
        align-items: center;
        margin-right: rpx(20);

        img {
          display: block;
          width: rpx(34);
          height: rpx(34);
          margin-right: rpx(10);
        }
      }

      &-hot {
        color: #ed207b;
      }

      &-evaluate {
        color: #9bdbda;
      }

      &-sign {
        position: absolute;
        right: 0;
        top: 0;
        width: rpx(60);
        height: rpx(60);

        img {
          @include cover;
        }
      }

      &-lv {
        position: absolute;
        left: 0;
        top: 0;
        width: rpx(60);
        height: rpx(60);
        color: #ed207b;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include cover;
          width: rpx(40);
          height: rpx(40);
        }

        span {
          position: absolute;
          bottom: rpx(5);
          right: rpx(5);
          font-size: rpx(24);
          font-style: italic;
        }
      }

      &-star {
        display: flex;
        margin-top: rpx(10);

        &-icon {
          color: #ed207b;
          margin: 0 rpx(2);
        }
      }

      &-certificates {
        display: flex;
        flex-wrap: wrap;
        margin-top: rpx(20);

        &-element {
          color: #fff;
          background-color: #ed207b;
          padding: 0 rpx(6);
          line-height: rpx(40);
          font-size: rpx(24);
          border-radius: rpx(10);
          margin-right: rpx(16);
          margin-bottom: rpx(16);
        }
      }
    }

    // 详情
    &-details {
      // width: 800px;
      // height: 340px;

      &-body {
        // padding: rpx(20);
        display: flex;
        flex-direction: column;
      }

      // 详情-文本
      &-textarea {
        width: 100%;
      }

      &-textarea &-content {
        overflow: hidden;
        height: rpx(160);
      }

      // 详情-列表
      &-line {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rpx(20);
      }

      &-line &-item {
        &:nth-of-type(1) {
          width: 44%;
        }

        &:nth-of-type(2) {
          width: 36%;
        }

        &:nth-of-type(3) {
          width: 20%;
        }
      }

      // 详情-元素
      &-item {
        display: flex;
      }

      // 详情-标题
      &-title {
        color: #ed207b;
        font-size: rpx(24);
        line-height: rpx(32);
      }

      // 详情-内容
      &-content {
        color: #353535;
        font-size: rpx(24);
        line-height: rpx(32);
        flex: 1;
        width: 0;
      }
    }

    // 段落
    &-section {
      padding: 0 rpx(30);
      border-bottom: rpx(10) solid #eeeeee;
      overflow: hidden;

      // 段落-顶部
      &-header {
        display: flex;
        align-items: center;
        height: rpx(80);
        line-height: rpx(80);
        font-size: rpx(30);
        color: #9bdbd9;
        // background-color: #ed207b;

        &-icon {
          margin-left: rpx(-16);
        }
      }
      // 段落-主体
      &-body {
      }
    }

    // flex
    &-flex {
      display: flex;
      margin: auto;
      margin-top: 40px;
      width: 1200px;
    }

    // 培训
    &-training {
      // 培训-列表
      &-list {
        // padding: 20px;
      }

      &-empty {
        color: #353535;
        height: rpx(100);
        line-height: rpx(100);
        padding-bottom: rpx(40);
        font-size: rpx(28);
        text-align: center;
      }

      // 培训-元素
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: rpx(20);
        font-size: rpx(24);

        &-date {
          color: #ed207b;
        }

        &-title {
          color: #353535;
          @include ellipsis;
          width: 0;
          flex: 1;
          margin: 0 rpx(20);
        }

        &-lv {
          color: #ed207b;
          @include ellipsis;
          width: rpx(150);
        }
      }
    }

    // 照片
    &-pic {
      margin-top: rpx(30);

      &-list {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        width: rpx(330);
        height: rpx(330);
        margin-right: rpx(30);
        margin-bottom: rpx(30);

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        img {
          @include cover;
        }
      }
    }

    // 评论
    &-evaluate {
      margin-top: rpx(30);
      margin-bottom: rpx(30);

      // 评论-列表
      &-list {
        // padding: 20px;
      }

      // 评论-元素
      &-item {
        padding-bottom: rpx(40);
        margin-bottom: rpx(40);
        border-bottom: rpx(2) dashed #cecece;

        // 评论-元素-顶部
        &-header {
          display: flex;
          align-items: center;
          font-size: rpx(24);
          color: #2f2f2f;
        }

        // 名称
        &-name,
        &-location {
          &::after {
            content: "|";
            display: inline-block;
            margin: 0 rpx(20);
          }
        }

        // 来自
        &-channel {
          margin-left: rpx(60);
          color: #ed207b;
        }

        // 内容
        &-content {
          color: #bcbcbc;
          margin-top: rpx(20);
          font-size: rpx(24);
        }
      }
    }

    //
    &-map {
      padding-bottom: rpx(10);

      &-img {
        @include cover;
      }
    }
  }
}
//
</style>
